import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    title: 'Spiritual Healing',
    loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'prices',
    title: 'Preise Spiritual Healing',
    loadComponent: () => import('./prices/prices.component').then((m) => m.PricesComponent),
  },
  {
    path: 'faq',
    title: 'FAQ',
    loadComponent: () => import('./faq/faq.component').then((m) => m.FaqComponent),
  },
  {
    path: 'history',
    title: 'Geschichte',
    loadComponent: () => import('./history/history.component').then((m) => m.HistoryComponent),
  },
  {
    path: 'team',
    title: 'Team',
    loadComponent: () => import('./team/team.component').then((m) => m.TeamComponent),
  },
  {
    path: 'message/:interest',
    loadComponent: () => import('./message/message.component').then((m) => m.MessageComponent),
  },
  {
    path: 'impressum',
    title: 'Erreichbarkeiten Spiritual Healing',
    loadComponent: () => import('./impressum/impressum.component').then((m) => m.ImpressumComponent),
  },
  {
    path: 'privacy-policy',
    title: 'Datenschutz (Privacy Policy)',
    loadComponent: () => import('./privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
